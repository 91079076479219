import { useContext, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { GlobalVideoBackupContext } from "../video_backup/VideoBackup";
import { VideoControlContext } from "../video_backup/VideoControlProvider";

export const StreamPlayer = ({ liveHls, debug = false, reload, controls = true, autoplay = true }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { playing } = useContext(GlobalVideoBackupContext);

  const { addPlayer = () => {}, removePlayer = () => {} } =
    useContext(VideoControlContext) || {};

  useEffect(() => {
    let player;

    const initializePlayer = () => {
      if (!videoRef.current) return; // Ensure video element is in the DOM

      player = videojs(videoRef.current, {
        autoplay: autoplay,
        controls: controls,
        muted: true,
        sources: [
          {
            src: liveHls,
            type: "application/x-mpegURL",
          },
        ],
        html5: {
          hls: {
            debug: debug,
            maxBufferLength: 30,
            maxMaxBufferLength: 60,
            maxBufferSize: 60 * 1000 * 1000,
            maxBufferHole: 0.5,
            liveSyncDurationCount: 3,
            fragLoadingTimeOut: 20000,
            fragLoadingMaxRetry: 6,
            fragLoadingRetryDelay: 2000,
            startLevel: -1,
            initialLiveManifestSize: 3,
            liveSyncDuration: 10, // Sync live stream to be 10 seconds behind live edge
          },
        },
      });

      // Save the player reference
      playerRef.current = player;
      // Check if addPlayer is available
      if (typeof addPlayer === "function") {
        addPlayer(playerRef.current);
      }
    };

    // Delay initialization to ensure the DOM is fully rendered
    const timeoutId = setTimeout(() => {
      initializePlayer();
    }, 100); // Delay of 100ms

    return () => {
      if (playerRef.current) {
        if (typeof removePlayer === "function") {
          removePlayer(playerRef.current);
        }
        playerRef.current.dispose();
      }
      clearTimeout(timeoutId); // Clean up the timeout
    };
  }, [liveHls, playing, debug]);

  return !liveHls ? (
    <div
      className="d-flex flex-column gap-3 align-items-center justify-content-center w-100 h-100"
      style={{ color: "white" }}
    >
      Error in playing stream
      <i className="fas fa-redo" onClick={() => reload()} style={{ fontSize: "1.5rem", cursor: "pointer" }}></i>
    </div>
  ) : (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-default-skin" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
