import { BaseEnum } from "../../../index";

export class StreamAnalyticInstancesAvailableTypes extends BaseEnum {
  static LINE_INTRUSION = "line_intrusion";
  static ZONE_INTRUSION = "zone_intrusion";
  static HEAT_MAP = "heat_map";
  static SAFETY_EQUIPMENT = "safety_equipment";
  static CAMERA_TAMPERING = "camera_tampering";
  static GLOBAL_NOTIFICATION = "global_notification";
  static VIDEO_BACKUP = "video_backup";
  static MOTION_DETECTION = "motion_detection";
  static FIRE_AND_SMOKE_DETECTION = "fire_and_smoke_detection";
  static FIRE_AND_SMOKE = "fire_and_smoke_detection";
}
