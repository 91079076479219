import React, { useContext, useState } from "react";
import moment from "moment";
import { GlobalVideoBackupContext } from "./VideoBackup";
import styled, { createGlobalStyle } from "styled-components";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

// Global styles to override MUI styles
const GlobalStyle = createGlobalStyle`
  .MuiPickersToolbar-root {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "rgba(94, 132, 195, 1)"
        : "#ffffff"} !important; /* Change based on the theme */
  }

  /* Typography styles for various toolbar elements */
  .MuiTypography-root {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set default color based on theme */
  }

  /* Clock styles */
  .MuiClock-clock {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"}; /* Set clock color based on theme */
  }

  .MuiClockNumber-root {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set hour numbers color */
  }

  .MuiClock-pin {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set clock pin color */
  }

  .MuiClockPointer-thumb {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set clock pointer color */
  }

  /* Additional styles for the DateTimePicker toolbar separator */
  .MuiDateTimePickerToolbar-separator,
  .MuiDateTimePickerToolbar-ampmLabel {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set separator and AM/PM label color */
  }

  /* Styles for the Calendar Header */
  .MuiPickersCalendarHeader-root {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "rgba(94, 132, 195, 1)"
        : "#ffffff"} !important; /* Set background color based on theme */
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set text color for the header */
  }

  /* Styles for the content wrapper of the date picker */
  .MuiPickersLayout-contentWrapper {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#2E3B55"
        : "#ffffff"} !important; /* Change background color for content wrapper */
    padding: 16px; /* Add padding to the content wrapper */
  }

  /* Set text color for the date and time selections */
  .MuiInputBase-input {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set input text color */
    border-bottom: none !important;
  }

  /* Optional: Add more styles to adjust the layout further */
  .MuiPickersLayout-container {
    border-radius: 10px; /* Optional: Add border radius to layout */
  }

  /* Style for the selected date */
  .Mui-selected {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set calendar button color */
  }

  /* Style for the calendar day */
  .MuiButtonBase-root {
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Set calendar button color */
  
    /* Style for focus state */
    &:focus {
      background: none !important; /* Remove background on focus */
      outline: none; /* Remove the default outline */
    }
  }  

  /* New styles for the selected day button */
  .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: #1976d2 !important; /* Set background color for selected day */
    color: #fff !important; /* Set text color for selected day */
  }

  /* Styles for MuiDialogActions-root */
  .MuiDialogActions-root {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#2E3B55"
        : "#f5f5f5"} !important; /* Background color based on theme */
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Text color based on theme */
  }
  .MuiYearCalendar-root{
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#000000"} !important; /* Text color based on theme */
  }

  /* New styles for the selected day button */
  .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }

  .MuiClockPointer-root{
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }

  .MuiClockPointer-thumb{
    background-color: rgba(94, 132, 195, 1) !important; /* Set background color for selected day */
    color: #ffffff !important; /* Set text color for selected day */
  }
`;

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center; /* Align items to the center */
  width: 100%; /* Ensure it takes full width */
`;

const StyledDateTimePicker = styled(MobileDateTimePicker)`
  .MuiInputBase-root {
    border: 1px solid #5e84c3; /* Add a border */
    cursor: pointer;
    border-radius: 25px; /* Set the border radius */
    box-shadow: none; /* Remove any shadow */
    font-size: 12px !important; /* Set font size to 12px */
    height: 33px !important;
    font-weight: 400;
    padding-top: 5px; /* Add some padding */
  }

  /* Remove the bottom border outline */
  .MuiOutlinedInput-notchedOutline {
    display: none; /* Hide the outline */
    border-bottom: none !important;
  }

  .MuiInputBase-input {
    border-radius: 25px; /* Ensure the input also has rounded corners */
    border: none; /* Ensure no border is shown */
  }

  label {
    font-size: 12px !important; /* Set label font size to 12px */
    margin-bottom: 5px; /* Adjust spacing between label and input */
    text-align: left; /* Align label text to the left */
    display: block; /* Ensure label takes full width */
    width: 100%; /* Set width to 100% */
    font-weight: 600 !important;
    color: ${({ theme }) =>
      theme === "dark"
        ? "#ffffff"
        : "#254478"} !important; /* Text color based on theme */
  }
`;

export const InputDates = () => {
  const { filterData, setFilterData } = useContext(GlobalVideoBackupContext);
  const [fromTime, setFromTime] = useState(dayjs());
  const [toTime, setToTime] = useState(dayjs());

  // Get the theme from the Redux store
  const { theme } = useSelector((state) => state.themeReducer);

  const handleFromDateTimeChange = (newValue) => {
    if (newValue) {
      setFromTime(newValue);
      const formattedDate =
        moment(newValue.toISOString())
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSSS") + "Z";
      setFilterData((prevData) => ({ ...prevData, from_time: formattedDate }));
    }
  };

  const handleToDateTimeChange = (newValue) => {
    if (newValue) {
      setToTime(newValue);
      const formattedDate =
        moment(newValue.toISOString())
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSSS") + "Z";
      setFilterData((prevData) => ({ ...prevData, to_time: formattedDate }));
    }
  };

  return (
    <>
      <GlobalStyle theme={theme} /> {/* Pass the theme prop here */}
      <div className='fieldsWrapper'>
        <Container>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // gap: "0.5rem",
                width: "100%",
              }}
            >
              <label htmlFor='from_time' style={{ fontWeight: "600" }}>
                From
              </label>
              <StyledDateTimePicker
                value={fromTime}
                onChange={handleFromDateTimeChange}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // gap: "0.5rem",
                width: "100%",
              }}
            >
              <label htmlFor='to_time' style={{ fontWeight: "600" }}>
                To
              </label>
              <StyledDateTimePicker
                value={toTime}
                onChange={handleToDateTimeChange}
              />
            </div>
          </LocalizationProvider>
        </Container>
      </div>
    </>
  );
};
