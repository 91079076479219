import { StreamAnalyticInstanceConfig } from "../../../../config/instance/stream/analytics";
import { StreamAnalyticInstancesAvailableTypes } from "../../../../config/enums/instance/stream/analytic";
import LineIntrusionDetectorConfigView from "../../../../components/instance/stream/analytics/line_intrusion_detector/components/configure/Container";
import ZoneIntrusionDetectorConfigView from "../../../../components/instance/stream/analytics/zone_intrusion_detector/components/configure/Container";
import HeatMapConfigView from "../../../../components/instance/stream/analytics/heat_map/Container";
import SafetyEquipmentDetectionConfigView from "../../../../components/instance/stream/analytics/safety_equipment_detector/Container";
import MotionDetectionConfigView from "../../../../components/instance/stream/analytics/motion_detection/Container";
import VideoBackupConfigView from "../../../../components/instance/stream/analytics/video_backup/Container";
import CameraTamperingDetectionConfigView from "../../../../components/instance/stream/analytics/camera_tampering/Container";
import FireSmokeDetectionConfigView from "../../../../components/instance/stream/analytics/fire_and_smoke_detection/components/configure/Container";

export class StreamAnalyticRoutingConfig {
  static get analyticComponentMapping() {
    return {
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.LINE_INTRUSION
      ].shortIdentifier]: LineIntrusionDetectorConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.ZONE_INTRUSION
      ].shortIdentifier]: ZoneIntrusionDetectorConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.HEAT_MAP
      ].shortIdentifier]: HeatMapConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.SAFETY_EQUIPMENT
      ].shortIdentifier]: SafetyEquipmentDetectionConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.CAMERA_TAMPERING
      ].shortIdentifier]: CameraTamperingDetectionConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.VIDEO_BACKUP
      ].shortIdentifier]: VideoBackupConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.MOTION_DETECTION
      ].shortIdentifier]: MotionDetectionConfigView,
      [StreamAnalyticInstanceConfig.Definitions[
        StreamAnalyticInstancesAvailableTypes.FIRE_AND_SMOKE_DETECTION
      ].shortIdentifier]: FireSmokeDetectionConfigView,
    };
  }
}
