import { createContext, useRef, useState } from "react";

// Context to store video players
export const VideoControlContext = createContext();

export const VideoControlProvider = ({ children }) => {
  const videoPlayersRef = useRef([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const addPlayer = (player) => {
    videoPlayersRef.current.push(player);

    // Set duration and sync time when a new player is added
    player.on("loadedmetadata", () => {
      setDuration(player.duration());
      player.currentTime(currentTime); // Start at the current time
    });

    // Update current time periodically
    player.on("timeupdate", () => {
      setCurrentTime(player.currentTime());
    });
  };

  const removePlayer = (player) => {
    videoPlayersRef.current = videoPlayersRef.current.filter((p) => p !== player);
  };

  const playAll = () => {
    videoPlayersRef.current.forEach((player) => player.play());
  };

  const pauseAll = () => {
    videoPlayersRef.current.forEach((player) => player.pause());
  };

  const forwardAll = (seconds = 10) => {
    videoPlayersRef.current.forEach((player) => {
      player.currentTime(player.currentTime() + seconds);
    });
  };

  const backwardAll = (seconds = 10) => {
    videoPlayersRef.current.forEach((player) => {
      player.currentTime(player.currentTime() - seconds);
    });
  };

  const handleTimelineClick = (e, timelineRef, duration) => {
    const rect = timelineRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const newTime = (clickPosition / timelineRef.current.scrollWidth) * duration; // Calculate new time

    // Sync player time to the new time
    videoPlayersRef.current.forEach((player) => {
      player.currentTime(newTime); // Sync player time to the new time
    });
  };

  return (
    <VideoControlContext.Provider
      value={{
        addPlayer,
        removePlayer,
        playAll,
        pauseAll,
        forwardAll,
        backwardAll,
        handleTimelineClick,
        currentTime,
        setCurrentTime, // Expose setCurrentTime here
        duration,
      }}
    >
      {children}
    </VideoControlContext.Provider>
  );
};
