import React, { createContext, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { GlobalUserProfileContext } from "../../../../App";
import { MainWrapper } from "../../../MainWrapper";
import { FilterContainer } from "../../../global/filters/Container";
import { useSelector } from "react-redux";
import { CameraStreamGridContainer } from "./CameraStreamsGridContainer";
import { InputDates } from "./InputDates";
import PlaybackSection from "./PlaybackSection";
import { VideoControlProvider } from "./VideoControlProvider";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  #livestreams-wrapper {
    position: relative;
  }
  .fieldsWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 4;

    label {
      color: ${(props) => (props.theme === "dark" ? "white" : "black")};
    }
    input {
      color: ${(props) =>
        props.theme === "dark" ? "white !important" : "black !important"};
      text-indent: 0.5rem !important;
    }
  }

  .filters-content {
    justify-content: flex-start !important;
    gap: 1rem !important;
  }

  .stream-wrapper {
    width: 100%;
    height: fit-content;
  }

  .video-container-grid {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template: "navigator content" 100% / 300px 1fr;
    padding: 20px;

    @media (max-width: 1200px) {
      grid-template:
        "navigator"
        "content" minmax(10px, 1fr) / auto;
      padding: 10px 5px;
    }
  }

  .liveStreamVideosSection {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(0px, 1fr) auto;
    grid-template-columns: 100%;
  }

  @media (max-width: 1431px) {
    .filters-content {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
`;

export const GlobalVideoBackupContext = createContext({});

export const GlobalVideoBackupContainer = () => {
  const { isFetchingProfile: isFetching, profile } = useContext(
    GlobalUserProfileContext
  );
  const streamIdsRef = useRef(new Set());
  const [filterData, setFilterData] = useState({});
  const { theme } = useSelector((state) => state.themeReducer);
  const [duration, setDuration] = useState(15 * 60);
  const [speed, setSpeed] = useState(1);

  const handleFilterData = (data) => {
    setFilterData((prev) => ({
      ...prev,
      SubUser: data.SubUser,
      Site: data.Site,
      Camera: data.Camera,
    }));
  };

  return (
    <GlobalVideoBackupContext.Provider
      value={{
        streamsData: filterData?.Camera || [],
        setFilterData,
        filterData,
        streamIdsRef,
      }}
    >
      <MainWrapper
        parent="videobackup"
        isFetching={isFetching}
        profile={profile}
        pageTitle="VideoBackup"
      >
        <StyledWrapper theme={theme}>
          <div className="video-container-grid d-flex flex-column">
            <div
              className={`mt-2 tab-content-header ${
                theme === "dark" &&
                "tab-content-header-dark-bg tab-content-header-dark-text"
              }`}
              style={{ gridArea: "navigator" }}
            >
              <div className="filters-content">
                <InputDates />

                <FilterContainer
                  componentName={"FilterByUserSiteCamera"}
                  setterCallback={handleFilterData}
                  liveSearch={true}
                  backup={true}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                gridArea: "content",
              }}
            >
              <VideoControlProvider>
                {/* <div className='d-flex stream-wrapper mt-4 flex-grow-1'> */}
                <div className="liveStreamVideosSection">
                  <CameraStreamGridContainer />
                  <PlaybackSection
                    setSpeed={setSpeed}
                    speed={speed}
                    duration={duration}
                    setDuration={setDuration}
                    filterData={filterData}
                  />
                </div>
                {/* </div> */}
              </VideoControlProvider>
            </div>
          </div>
        </StyledWrapper>
      </MainWrapper>
    </GlobalVideoBackupContext.Provider>
  );
};

// import { useState, useContext, useEffect, useRef } from "react";
// import { FaPlay, FaPause } from "react-icons/fa";
// import { MdOutlineForward10, MdOutlineReplay10 } from "react-icons/md";
// import Select from "react-select";
// import styled from "styled-components";
// import { VideoControlContext } from "./VideoControlProvider";
// import { GlobalVideoBackupContext } from "./VideoBackup";
// import Arrow from "../../../../static/img/Vector 59.png";

// const StylesWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 10px;
//   // gap: 10px;
//   position: relative;
//   // z-index: 1;
//   padding: 10px;
//   border-radius: 10px;
//   color: rgb(255, 255, 255);
//   .wrapper {
//     margin-top: 10px;
//     background: linear-gradient(135deg, #254478, #1c2d4d);
//     color: white;
//     padding: 1rem;
//     width: 100%;
//     position: relative;
//     bottom: 0;
//     border-radius: 10px;
//     overflow: hidden;
//   }
//   .no-scrollbar::-webkit-scrollbar {
//     display: none;
//   }

//   .no-scrollbar {
//     -ms-overflow-style: none;
//     scrollbar-width: none;
//   }
//   .gridClass {
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 1rem;
//     margin-bottom: 1rem;
//   }

//   @media (min-width: 768px) {
//     .grid {
//       grid-template-columns: repeat(3, 1fr);
//     }
//   }

//   .flex {
//     display: flex;
//     align-items: center;
//   }

//   .flex > *:not(:last-child) {
//     margin-right: 1rem;
//   }

//   .form {
//     display: flex;
//     align-items: center;
//     gap: 0.25rem;
//   }

//   .button1 {
//     background-color: transparent;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border: 1px solid #d1d5db;
//     border-radius: 50%;
//     padding: 0.75rem;
//     height: 1.75rem;
//     color: white;
//   }
//   @media (min-width: 768px) {
//     /* Tailwind's md breakpoint is usually 768px */
//     .gridClass {
//       grid-template-columns: repeat(
//         3,
//         1fr
//       ); /* Change to 3 columns on medium screens */
//     }
//   }
//   .inputClass {
//     background-color: transparent;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     color: white;
//     border: 1px solid #4b5563;
//     width: 4rem;
//     height: 2rem;
//     border-radius: 40px;
//     font-weight: 500;
//     text-align: center;
//     font-size: 0.875rem;
//   }

//   .buttonsWrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 1rem;
//   }

//   .buttonVarient {
//     color: white;
//     background: transparent;
//     padding: 0.5rem;
//     transition: all 0.2s ease;
//   }
//   .selectWrapper {
//     display: flex;
//     align-items: center;
//   }
//   .buttonVarient:hover {
//     color: #9ca3af;
//   }

//   .scrollableTimeline {
//     position: relative;
//     overflow-x: scroll;
//   }

//   .timeline-marker {
//     position: absolute;
//     height: 100%;
//     border-left: 1px solid #4b5563;
//   }

//   .voiletDiv {
//     position: relative;
//     height: 5rem;
//     display: flex;
//     align-items: center;
//   }

//   .label {
//     position: absolute;
//     font-size: 0.75rem;
//     color: #e5e7eb;
//   }

//   .dummy {
//     position: absolute;
//     height: 1rem;
//     width: 1px;
//     background-color: #9ca3af;
//   }

//   .red-marker {
//     position: absolute;
//     height: 5rem;
//     width: 2px;
//     background-color: red;
//   }

//   .graph {
//     position: relative;
//     width: 100%;
//     height: 135px;
//     border-radius: 20px;
//     /* border: 1px solid red; */
//     background-color: #f3f3f3;
//     display: flex;
//   }
//   .grayportion {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     border-radius: 20px;
//     background-color: #f3f3f3;
//     display: flex;
//   }
//   .vidoesname {
//     /* border: 1px solid red; */
//     width: 100%;
//     height: 100%;
//     display: flex;
//   }

//   .namesection {
//     width: 100%;
//   }

//   .graphdata {
//     padding: 5px;
//   }

//   .names {
//     color: black;
//     font-size: 16px;
//     display: flex;
//     /* flex-direction: column; */
//     width: 100%;
//     align-items: center;
//     margin-top: 5px;
//     gap: 7px;
//   }

//   span {
//     color: black;
//     font-size: 16px;
//     width: 15%;
//   }

//   .line {
//     height: 2px;
//     background-color: black;
//     width: 80%;
//   }

//   .graphline {
//     width: 100px;
//     height: 19px;
//     border-radius: 6px;
//     margin-top: -10px;
//     background-color: #254478;
//   }

//   .nocamera {
//     text-align: center;
//     color: black;
//   }
// `;

// const customSelectStyles = {
//   control: (provided) => ({
//     ...provided,
//     border: "1px solid #ccc",
//     backgroundColor: "transparent",
//     color: "white",
//     boxShadow: "none",
//     "&:hover": {
//       border: "1px solid #aaa", // Change border color on hover
//     },
//   }),
//   menu: (provided) => ({
//     ...provided,
//     zIndex: 9999,
//     height: "80px", // Set a maximum height for the dropdown
//     overflowY: "scroll !important", // Enable vertical scrolling when necessary
//     backgroundColor: "white", // Set background color for the dropdown menu
//   }),

//   menuList: (provided) => ({
//     ...provided,
//     overflowY: "scroll !important",
//     height: "80px",
//     maxHeight: "80px !important",
//   }),
//   option: (provided, { isFocused, isSelected }) => ({
//     ...provided,
//     cursor: "pointer",
//     outline: "none",
//     backgroundColor: isFocused ? "#f0f0f0" : isSelected ? "#d1d1d1" : "white",
//     color: isFocused || isSelected ? "black" : "black", // Keep text color consistent
//     padding: "10px", // Add some padding for better spacing
//     height: "40px", // Adjust height for individual options
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     color: "white", // Color of the selected value
//   }),
//   indicatorSeparator: (provided) => ({
//     ...provided,
//     display: "none", // Hide the indicator separator
//   }),
// };

// const PlaybackSection = ({ setSpeed, speed, setDuration, filterData }) => {
//   const {
//     playAll,
//     pauseAll,
//     currentTime,
//     duration,
//     forwardAll,
//     backwardAll,
//     handleTimelineClick,
//   } = useContext(VideoControlContext);
//   const [playing, setPlaying] = useState(false);
//   const { streamsData } = useContext(GlobalVideoBackupContext);
//   const progressBarRef = useRef(null); // Create a ref for the progress bar
//   const [cameraName, setCameraName] = useState("");

//   const handlePlayPause = async () => {
//     try {
//       if (playing) {
//         await pauseAll(); // Wait for the pause promise to resolve
//       } else {
//         await playAll(); // Wait for the play promise to resolve
//       }
//       setPlaying((prev) => !prev); // Toggle the playing state after play/pause is done
//     } catch (error) {
//       console.error("Error while playing/pausing videos:", error);
//     }
//   };

//   const speedOptions = [
//     { value: 0.5, label: "0.5x" },
//     { value: 1, label: "1x" },
//     { value: 2, label: "2x" },
//     { value: 3, label: "3x" },
//   ];

//   const formatTime = (timeInSeconds) => {
//     if (isNaN(timeInSeconds)) return "0:00";
//     const hours = Math.floor(timeInSeconds / 3600);
//     const minutes = Math.floor((timeInSeconds % 3600) / 60);
//     const seconds = Math.floor(timeInSeconds % 60);
//     return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
//       seconds < 10 ? "0" : ""
//     }${seconds}`;
//   };
//   const handleProgressClick = (e) => {
//     if (progressBarRef.current) {
//       handleTimelineClick(e, progressBarRef, duration); // Call the handleTimelineClick from context
//     }
//   };

//   // Effect to handle new streamsData but preserve the currentTime across streams
//   useEffect(() => {
//     if (streamsData && streamsData.length > 0) {
//       setDuration(duration); // Update duration
//       if (playing) {
//         playAll(); // Continue playback for all streams
//       } else {
//         pauseAll(); // Pause if playback was paused
//       }
//     }
//   }, [streamsData, playing, playAll, pauseAll, duration]);

//   useEffect(() => {
//     if (playing && currentTime >= duration) {
//       setPlaying(false); // Stop playback if currentTime reaches duration
//       pauseAll(); // Pause the video
//     }
//   }, [currentTime, duration, playing, pauseAll]);

//   console.log(filterData, "filter data");

//   useEffect(() => {
//     if (filterData?.Camera) {
//       const cameraNames = filterData.Camera.map((cam) => cam.value); // Extract camera values
//       setCameraName(cameraNames); // Update state with camera values
//     }
//   }, [filterData]);

//   return (
//     <StylesWrapper>
//       <div className="no-scrollbar wrapper">
//         <div className="gridClass">
//           <div className="flex">
//             <div className="selectWrapper">
//               <Select
//                 value={speedOptions.find((option) => option.value === speed)}
//                 onChange={(selectedOption) => setSpeed(selectedOption.value)}
//                 options={speedOptions}
//                 styles={customSelectStyles}
//               />
//             </div>
//           </div>

//           <div className="buttonsWrapper">
//             <div
//               className="buttonVarient"
//               onClick={() => backwardAll(10)}
//               disabled={streamsData?.length === 0}
//             >
//               <MdOutlineReplay10 size={32} />
//             </div>

//             <button
//               className="buttonVarient"
//               onClick={handlePlayPause}
//               disabled={streamsData?.length === 0}
//             >
//               {playing ? <FaPause size={16} /> : <FaPlay size={16} />}
//             </button>

//             <div
//               className="buttonVarient"
//               onClick={() => forwardAll(10)}
//               disabled={streamsData?.length === 0}
//             >
//               <MdOutlineForward10 size={32} />
//             </div>
//           </div>
//         </div>

//         {/* Progress Bar Section */}
//         <div style={{ width: "100%", marginTop: "10px" }}>
//           <div
//             ref={progressBarRef} // Add ref to the progress bar
//             style={{
//               height: "10px",
//               background: "#e0e0e0",
//               borderRadius: "5px",
//               overflow: "hidden",
//               position: "relative",
//               cursor: "pointer", // Make the progress bar clickable
//             }}
//             onClick={handleProgressClick} // Handle clicks on the progress bar
//           >
//             <div
//               style={{
//                 width:
//                   duration > 0 ? `${(currentTime / duration) * 100}%` : "0%",
//                 height: "100%",
//                 background: "green",
//                 transition: "width 0.1s",
//               }}
//             />
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               marginTop: "5px",
//             }}
//           >
//             <span>{formatTime(currentTime)}</span>
//             <span>{formatTime(duration)}</span>
//           </div>

//           {/* graph start */}
//           <div className="graph">
//             <div className="grayportion">
//               <div className="vidoesname">
//                 <div className="namesection">
//                   <div className="graphdata">
//                     {cameraName.length > 0 ? (
//                       cameraName.map((camera, index) => (
//                         <div className="names" key={index}>
//                           <span>{camera}</span>
//                           <div className="line">
//                             <div className="graphline"></div>
//                           </div>
//                           <img src={Arrow} alt="" />
//                         </div>
//                       ))
//                     ) : (
//                       <div className="nocamera">No Cameras Available</div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* graph end */}
//         </div>
//       </div>
//     </StylesWrapper>
//   );
// };

// export default PlaybackSection;
